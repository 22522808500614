// 实时值历史表api
import request from '../../../utils/request'
const api_name = '/device-history-value'

export default {
    getOneByDeviceHistoryStatusId(id) { //根据状态历史表中的id查询实时值历史表中的一条数据
        return request({
            url: `${api_name}` + '/getOneByDeviceHistoryStatusId/' + id,
            method: 'get'
        })
    },
    getAll(currentPage, type, superDeviceId, superValueName) { //分页查询，设备类型条件查询
        let typeName = "";
        let deviceId = "";
        let valueName = "";
        
        if (type != "") {

            typeName = '&typeName=' + type
        }
        if (superDeviceId != "") {
            deviceId = '&deviceId=' + superDeviceId
        }
        if (superValueName != "") {
            valueName = '&valueName=' + superValueName
        }
        return request({
            url: `${api_name}` + '/getAll?number=' + currentPage + typeName + deviceId + valueName,
            method: 'get'
        })
    },
    getAllHistoryValue() {
        return request({
            url: `${api_name}` + '/getAllHistoryValue',
            method: 'get'
        })
    },
    getDayValue(deviceId,value,createTime,typeId){
        return request({
            url: `${api_name}` + '/getDayValue',
            method: 'post',
            data:{
                deviceId: deviceId,
                value: value,
                createTime: createTime,
                typeId: typeId
            }
        })
    }
}